import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Admin from "./components/Home/Admin";
import Registration from "./components/Registration/Registration";
import EditUser from "./components/CRUD/edit/editUser";
import AddUser from "./components/CRUD/add/addUser";
import AdminLogin from "./components/login/index";
import Notfound from "./components/Notfound";
import Response from "./components/Response/Response";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Registration />} />
        <Route path="/response" element={<Response />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<Admin />} />
        <Route path="admin/edit/:userId" element={<EditUser />} />
        <Route path="admin/add" element={<AddUser />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  );
}

export default App;

// import React, { useState } from 'react';
// import './login.css';

// const LoginForm = () => {
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const handleSignIn = () => {
//         setLoading(true);
//         setError(null);

//         fetch('http://localhost:3000/open-electron')
//             .then(response => response.text())
//             .then(data => {
//                 console.log(data);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error('Error:', error);
//                 setError('Failed to open executable.');
//                 setLoading(false);
//             });
//     };

//     return (
//         <div className="login-container">
//             <form className="login-form">
//                 <h2>Login Form</h2>
//                 <div className="form-group">
//                     <label htmlFor="email">Email</label>
//                     <input type="email" id="email" name="email" placeholder="Enter email" required />
//                 </div>
//                 <div className="form-group">
//                     <label htmlFor="password">Password</label>
//                     <input type="password" id="password" name="password" placeholder="Password" required />
//                 </div>
//                 <button type="button" onClick={handleSignIn} disabled={loading}>
//                     {loading ? 'Signing In...' : 'Sign In'}
//                 </button>
//                 {error && <p className="error-message">{error}</p>}
//             </form>
//         </div>
//     );
// };

// export default LoginForm;
