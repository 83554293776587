import React from 'react';
import './ResponseStyle.css';
import SuccessIcon from './../../assets/Purchase_Success.png'

const Response = () => {
  return (
    <div className="response_container">
      <div className="response_content">
        <img src={SuccessIcon} alt="" />
        <h1> Registration Successfull !</h1>
        {/* <p style={{fontSize: '28px', }}></p> */}
        <p>
          Since this is where users will reach immediately after opting in to your offer, <br />
          take a chance to thank them and let them know the next step.
        </p>
        <button className="download_btn">Download Receipt</button>
      </div>
    </div>
  );
};

export default Response;
