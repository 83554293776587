import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ragistration.css"
import images1 from "../../assets/images/ragestration.png"
import { Navigate, useNavigate } from 'react-router-dom';

export default function Registration() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        mobile_number: '',
        email: '',
        password: '',
        confirm_password: '',
        amount: '500',
        additional_info: ''
    });

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    });

    const [mobileError, setMobileError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'name') {
            const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
            setFormData((prevState) => ({
                ...prevState,
                [name]: filteredValue,
            }));
        } else if (name === 'mobile_number') {
            const filteredValue = value.replace(/[^0-9]/g, '').slice(0, 10);
            setFormData((prevState) => ({
                ...prevState,
                [name]: filteredValue,
            }));
            setMobileError('');
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handlePayment = async (e) => {
        e.preventDefault();

        if (formData.mobile_number.length !== 10) {
            setMobileError('Mobile number must be exactly 10 digits.');
            return;
        }

        if (formData.password !== formData.confirm_password) {
            toast.error("Passwords do not match!");
            return;
        }

        const options = {
            key: "rzp_test_Rz8NSLJbl4LBA5",
            amount: 500 * 100,
            currency: "INR",
            name: "Multi Font Selector",
            description: "Writing has become easy with font-selector to write using multiple fonts as per your needs",
            image: "https://cdn-icons-png.flaticon.com/256/3917/3917754.png",
            handler: async function (response) {
                try {
                    const backendResponse = await axios.post('https://font-controller-backend-ashy.vercel.app/api/v1/register/add', {
                        ...formData,
                        razorpay_payment_id: response.razorpay_payment_id
                    });

                    if (backendResponse.data.success) {
                        toast.success('Registration and payment successful!');
                        navigate('/response');
                    } else {
                        toast.error('Registration failed after payment.');
                    }
                } catch (error) {
                    console.error('There was an error during registration!', error);
                    toast.error('Registration failed after payment.');
                }
            },
            prefill: {
                name: formData.name,
                email: formData.email,
                contact: formData.mobile_number
            },
            notes: {
                address: "Your Company Address"
            },
            theme: {
                color: "#3399cc"
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();

        rzp1.on('payment.failed', function (response) {
            toast.error('Payment failed. Please try again.');
        });
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    return (
        <>
            <div className="App">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 image-container">
                            <img src={images1} alt="Registration Illustration" className="registration-image" />
                        </div>
                        <div className="col-lg-6 col-md-12 form-container">
                            <h2>Sylfaen Registration</h2>
                            <form onSubmit={handlePayment} className='form-inputt'>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Enter your name"
                                            onChange={handleChange}
                                            value={formData.name}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="mobile_number">Mobile</label>
                                        <input
                                            type="text"
                                            id="mobile_number"
                                            name="mobile_number"
                                            placeholder="Enter your mobile number"
                                            onChange={handleChange}
                                            value={formData.mobile_number}
                                            required
                                        />
                                        {mobileError && <div className="error-message">{mobileError}</div>}
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="password">Create Password</label>
                                        <div className="password-container">
                                            <input
                                                type={showPassword.password ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                placeholder="Create your password"
                                                onChange={handleChange}
                                                required
                                            />
                                            <button type="button" onClick={() => togglePasswordVisibility('password')} className="password-toggle-btn-registration">
                                                <FontAwesomeIcon icon={showPassword.password ? faEyeSlash : faEye} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="confirm_password">Confirm Password</label>
                                        <div className="password-container">
                                            <input
                                                type={showPassword.confirmPassword ? "text" : "password"}
                                                id="confirm_password"
                                                name="confirm_password"
                                                placeholder="Confirm your password"
                                                onChange={handleChange}
                                                required
                                            />
                                            <button type="button" onClick={() => togglePasswordVisibility('confirmPassword')} className="password-toggle-btn-registration1">
                                                <FontAwesomeIcon icon={showPassword.confirmPassword ? faEyeSlash : faEye} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="amount">Amount</label>
                                        <input
                                            type="number"
                                            id="amount"
                                            name="amount"
                                            placeholder="Pay 500 INR Rupees"
                                            value="500"
                                            disabled
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Additional Information</label>
                                        <textarea
                                            id="additional_info"
                                            name="additional_info"
                                            placeholder="Enter your message"
                                            rows="3"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="submit-btn">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
