import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './layout.css';
import ProfileImage from "../assets/images/Frame 16.png";
import Notification from "../assets/images/bxs_notification.png";
import Search from "../assets/images/Search.png";
import Logo from "../assets/fonts-logo.png"

const Layout = ({ children }) => {
    const [activeLink, setActiveLink] = useState('/admin');

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    return (
        <div className="layout-container">
            <div className="sidebar" >
                <aside className='sidebar-admin' >
                    <div style={{ fontSize: 24, fontWeight: 600 }} className="logo-container">
                        Admin Dashboard
                        {/* <img src={Logo} alt="Logo" className="logo" /> */}
                        <div className='dashboard-hr'></div>
                    </div>
                    <ul>
                        <li className={activeLink === '/admin/dashboard' ? 'active' : ''}>
                            <Link to="/admin/dashboard" onClick={() => handleLinkClick('/admin/dashboard')}>User Management</Link>
                        </li>
                    </ul>
                </aside>
            </div>
            <div className="header-container">
                <header className="header">
                    <div className="search-container">
                        <div>
                            <img src={Search} alt="User" className="user-icon-search" />

                        </div>
                        <div>
                            <input type="text" placeholder="Search" className="search-field" />

                        </div>
                    </div>
                    <div className="user-icon">
                        <img src={Notification} alt="User" className="user-icon-notification" />
                        <hr></hr>

                        <img src={ProfileImage} alt="User" className="user-icon-image" />
                    </div>
                </header>
                <main className="main-content">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default Layout;
