import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './index.css'; 
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import AdminImage from '../../assets/images/admin.png';

export default function AdminLogin() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('https://font-controller-backend-ashy.vercel.app/api/v1/admin/login', formData);
            if (response.data.success) {
                setLoading(false);
                toast.success('Login successful!');
                setTimeout(() => {
                    navigate('/admin/dashboard');
                }, 1000);
            } else {
                setLoading(false);
                toast.error('Login failed. Please check your credentials.');
            }
        } catch (error) {
            setLoading(false);
            toast.error('Error during login. Please try again.');
            console.error('Error during login:', error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <div className="login-page-container">
            {loading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <ToastContainer />
            <div className="admin-login-content">
                <div className="admin-image-container">
                    <img src={AdminImage} alt="Admin" />
                </div>
                <div className="login-box">
                    <h2 className="logo-box">Sylfaen Login</h2>
                    <form onSubmit={handleSubmit} className='admin-form'>
                        <div className="form-group-admin">
                            <label htmlFor="email">Email<span className="required">*</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter your email"
                                onChange={handleChange}
                                value={formData.email}
                                required
                            />
                        </div>
                        <div className="form-group-admin">
                            <label htmlFor="password">Password</label>
                            <div className="password-container-admin">
                                <div></div>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    onChange={handleChange}
                                    value={formData.password}
                                    required
                                />
                                 <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="togglePassword-admin"
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                           
                        </div>
                        <button type="submit" className="submit-btn-admin" disabled={loading}>
                            Log in
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

